import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Card from '../components/card'
import ColumnAlternating from '../components/columnAlternatingStyled'
import Wistia from '../components/wistiaFeed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        {location.pathname != '/resources/videos/' ? 
          <Hero
            class="intro-65"
            image={post.frontmatter.image.childImageSharp.fluid}
            foreimage={null}
            imageAltText={post.frontmatter.alttext}
            title={post.frontmatter.title}
            subtitle={post.frontmatter.subtitle}
            ctatext={post.frontmatter.ctatext}
            ctaslug={post.frontmatter.ctaslug}    
            type="root"
          />
        :
          <section className="intro-65" >
            <Wistia wistiaId="t6v53j4yt0"/>
          </section>
        }

        {post.html && (
          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.section &&
          <>
            {post.frontmatter.section.map((sections, index) => (
              <>
                {sections.placement === 'cards' &&
                  <>
                    {sections.feature.filter(resource => resource.featured === true).length > 0 &&
                      <section className="bg-light-blue">
                        <MDBContainer>
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                            Featured {post.frontmatter.name.toLowerCase()}
                          </h2>
                          <MDBRow>
                            {sections.feature.filter(resource => resource.featured === true).length === 1 ?
                              <>
                                {sections.feature.filter(resource => resource.featured === true).map(features => (
                                  <ColumnAlternating
                                    key={1}
                                    textCol={'7'}
                                    imageCol={'5'}
                                    title={features.title}
                                    subtitle={null}
                                    subtitlecolour="text-very-dark-blue"
                                    description={features.subtitle}
                                    imageUrl={features.image.childImageSharp.fluid}
                                    imageAltText={features.alttext}
                                    placement="left"
                                    titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                                    link={features.link}
                                    colour="ficon-ue"
                                  />
                                ))}
                              </>
                              :
                              <>
                                {sections.feature.filter(resource => resource.featured === true).map((features, index) => (
                                  <Card
                                    key={index}
                                    collg="4"
                                    colmd="6"
                                    height="3.5rem"
                                    title={features.title}
                                    subtitle=""
                                    description={features.subtitle}
                                    imageUrl={features.image ? features.image.childImageSharp.fluid : null}
                                    link={features.link}
                                    titleclass="title-card-small"
                                    descriptionClass="text-card-small"
                                  />
                                ))}
                              </>
                            }
                          </MDBRow>
                        </MDBContainer>
                      </section>
                    }

                    <section className={sections.feature.filter(resource => resource.featured === true).length > 0 ? 'bg-white' : 'bg-light-blue'}>
                      <MDBContainer>
                        <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">{sections.title}</h2>
                        <MDBRow>
                          {sections.feature.filter(resource => resource.featured === false).map((features, index) => (
                            <Card
                              key={index}
                              collg="4"
                              colmd="6"
                              height="3.5rem"
                              title={features.title}
                              subtitle=""
                              description={features.subtitle}
                              imageUrl={features.image ? features.image.childImageSharp.fluid : null}
                              link={features.link}
                              titleclass="title-card-small"
                              descriptionClass="text-card-small"
                            />
                          ))}
                        </MDBRow>
                      </MDBContainer>
                    </section>
                  </>
                }

                {sections.placement === 'list' &&
                  <section className={index % 2 ? 'bg-white' : 'bg-light-blue'}>
                    <MDBContainer>
                      <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium pb-5">{sections.title}</h3>
                      {sections.feature.map(features => (
                        <MDBRow className="px-3">
                          <MDBCol lg="12" md="12">
                            <MDBRow
                              style={{
                                minHeight: features.subtitle ? '8rem' : '4rem',
                                marginBottom: '5rem',
                                borderLeft: '6px solid #009b3e',                                
                              }}
                              className="bg-white rounded innovation-box p-4"
                            >
                              <MDBCol lg="9" md="7" className="my-auto">
                                <h4 className="font-w-600 text-extra-large mb-0">{features.title}</h4>
                                {features.subtitle && <p className="font-w-400 text-medium mb-0">{features.subtitle}</p>}
                              </MDBCol>
                              <MDBCol lg="3" md="5" className="my-auto">
                                {features.document && (
                                  <>
                                    <a
                                      href={features.document.publicURL}
                                      className="nav-link btn btn-mdb-color btn-sm-block float-md-right mt-3 mt-md-0"
                                      target="_blank"
                                      rel="noopener"
                                    >
                                      <FontAwesomeIcon
                                        icon={faDownload}
                                        className="mr-2"
                                      />{' '}
                                      Download
                                    </a>
                                  </>
                                )}
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      ))}
                    </MDBContainer>
                  </section>
                }
              </>
            ))}
          </>
        }
      </Layout>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "resources" } }
    ) {
      frontmatter {
        meta {
          title
          description
        }
        name 
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug
        section {
          title
          subtitle
          placement
          feature {
            title
            subtitle
            image {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alttext
            linktext
            link
            document {
              publicURL
            }
            featured
          }
        }
      }
      html
    }
  }
`